import React, { useEffect, useState, useRef } from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import PassionStories from "../components/passionStories"
import jobsPreviousToDeadline from "../utils/helpers"
import SEO from "../components/seo"

export const data = graphql`
  query {
    passionStories: allSanityPost(
      filter: { category: { title: { eq: "Working at Bloom Nepal School" } } }
      sort: { fields: publishedAt, order: DESC }
      limit: 3
    ) {
      nodes {
        title
        slug {
          current
        }
        excerpt
        mainImage {
          asset {
            fluid {
              base64
              srcWebp
              srcSetWebp
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
    allSanityCareerPosition {
      nodes {
        title
        description
        deadline
        applicationProcedure
        _rawResponsibilities
        _rawBenefits
        _rawRequirements
      }
    }
  }
`
const serializers = {
  types: {
    code: props => (
      <pre data-language={props.node.language}>
        <code>{props.node.code}</code>
      </pre>
    ),
  },
}

const BlockContent = require("@sanity/block-content-to-react")

const JobCard = ({ job, setIsJobOnDisplay }) => {
  return (
    <div className="flex w-full h-full justify-center items-center overflow-scroll">
      <section className="w-full lg:px-32 -mt-16 py-16">
        <div className=" lg:px-16 lg:py-8 py-8">
          <div className="flex justify-between items-end">
            <h4 className="bg-blue-800 rounded-full px-2 text-sm text-white">
              Vacancy
            </h4>
            <button
              className="px-4 py-2 border-gray-800 border text-black no-select hover:bg-red-800 hover:text-white hover:border-transparent button-outline"
              onClick={() => setIsJobOnDisplay(false)}
            >
              X
            </button>
          </div>
          <div className="mt-8">
            <h3 className="lg:mt-2 section-head text-2xl text-red-800">
              {job.title}
            </h3>
            <p className="mt-2 text-base">{job.description}</p>
            <div className="mt-8 lg:mt-16 ">
              <h3 className="font-medium text-gray-800">Qualification</h3>
              <BlockContent
                blocks={job._rawRequirements}
                serializers={serializers}
                className="sanity-block"
              />
            </div>
            <div className="mt-8  lg:mt-16 ">
              <h3 className="font-medium text-gray-800">
                Position Description
              </h3>
              <BlockContent
                blocks={job._rawResponsibilities}
                serializers={serializers}
                className="sanity-block"
              />
            </div>
            <div className="mt-8  lg:mt-16 ">
              <h3 className="font-medium text-gray-800">Benefits</h3>
              <BlockContent
                blocks={job._rawBenefits}
                serializers={serializers}
                className="sanity-block"
              />
            </div>
            <div className="mt-8 lg:mt-12">
              <h3 className="font-medium text-gray-800">How to Apply</h3>
              <p className="text-base mt-2">
                {job.applicationProcedure !== null
                  ? job.applicationProcedure
                  : "Send your resume with the title Application: {Name of the Position} on the subject. Cover letter is recommended but optional."}
              </p>
            </div>

            <p className="font-medium text-sm mt-8">
              Apply by{" "}
              <span className="border-b border-gray-800">{job.deadline}</span>{" "}
              midnight{" "}
            </p>
          </div>
        </div>
      </section>
    </div>
  )
}

export default function ({ data }) {
  const jobs = jobsPreviousToDeadline(data.allSanityCareerPosition.nodes)
  const jobCard = useRef()
  const defaultJobOnDisplay = ""
  const [jobOnDisplay, setJobOnDisplay] = useState(defaultJobOnDisplay)
  const [isJobOnDisplay, setIsJobOnDisplay] = useState(false)

  useEffect(() => {
    if (jobOnDisplay !== defaultJobOnDisplay) {
      setIsJobOnDisplay(true)
    }
  }, [jobOnDisplay])

  return (
    <Layout>
      <SEO title="Careers"/>
      <section ref={jobCard} className="mt-0 m-0 p-0 left-auto relative">
        <div className="section-for-small-deivces">
          <div className="relative top-0 w-full">
            {isJobOnDisplay ? (
              <JobCard
                job={jobOnDisplay}
                setIsJobOnDisplay={setIsJobOnDisplay}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </section>
      <div className={`${isJobOnDisplay ? "opacity-100" : "opacity-100"}`}>
        {jobs.length === 0 ? (
          <section className="py-16 md:mt-16 lg:border-r lg:border-b border-gray-600">
            <h4 className="text-2xl text-center section-head">
              Sorry, no positions vacant currently.
            </h4>
            <p className="mt-4 text-center md:px-8">
              However, if you are really interested in a position, shoot us an
              email at
              <a href="mailto:info@bloomn.edu.np" className="in-link">
                {" "}
                info@bloomn.edu.np
              </a>{" "}
              mentioning why you think you would be a fit for Bloom Nepal and its
              mission.
            </p>
          </section>
        ) : (
          <section className="py-16 lg:mt-8 lg:border-r lg:border-b lg:border-gray-600">
            <div className="section-for-small-devices">
              <h2 className="section-head text-red-800">We're Hiring</h2>
              <ul className="lg:mt-6">
                {jobs.map(job => {
                  return (
                    <button
                      onClick={() => {
                        setJobOnDisplay(job)
                        setIsJobOnDisplay(true)
                        // scrolll to to because that's where the job card is
                        jobCard.current.scrollIntoView()
                        window.scrollBy(0, -200)
                      }}
                      className="button-outline"
                    >

                      <li
                        key={job.title}
                        className="lg:flex lg:justify-between lg:items-center border-gray-800 border lg:px-4 lg:py-4 lg:mt-4 mt-4 py-4 px-4 hover:bg-blue-800 hover:text-white hover:border-transparent cursor-pointer"
                        // onKeyDown={()=>{
                        //   setJobOnDisplay(job)
                        //   setIsJobOnDisplay(true)
                        //   // scrolll to to because that's where the job card is
                        //   jobCard.current.scrollIntoView()
                        //   window.scrollBy(0, -200)
                        // }}
                      >
                        <h3>{job.title}</h3>
                        <h6 className="hidden lg:block text-sm">
                          Apply by: {job.deadline}
                        </h6>
                      </li>
                    </button>
                  )
                })}
              </ul>
            </div>
          </section>
        )}
        {data.passionStories.nodes.length > 0 ? (
          <div className="m-0 lg:mt-32 mt-8 mx-4 sm:mx-8">
            <div className="">
              <h2 className="text-center section-head lg:px-16 text-red-700">
                Don't Take Our Word
              </h2>
              <p className="lg:px-16 text-center mt-4 lg:mb-12">
                Find yourself what it's like to work at Bloom from those who
                have had the experience.
              </p>
              <PassionStories data={data.passionStories} subtitle="bloom" />
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </Layout>
  )
}
